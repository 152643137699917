<template>
  <v-dialog
    v-model="modal"
    activator="parent"
    width="70%"
    @click:outside="$emit('update:modal', false)"
  >
    <v-card class="modal" :dark="$dark.get()">
      <v-card-title class="justify-center"
        >Добавьте выбранную зону доставки к филиалу</v-card-title
      >
      <v-card-text class="modal__text">
        <v-autocomplete
          v-model="searchZone"
          :items="DEP_ZONES"
          item-text="name"
          item-value="id"
          no-data-text="Ничего не найдено"
          class="mt-3"
          label="Выберите зону доставки"
          clearable
        />
      </v-card-text>
      <v-card-actions class="flex justify-end">
        <v-btn @click="$emit('update:modal', false)">Закрыть</v-btn>
        <v-btn color="primary" @click="bindZone()">Добавить</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import api from "@/api";
import showMessage from "@/Functions/message";
export default {
  name: "AddZoneToDepartmentModal",
  props: {
    modal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchZone: null,
    };
  },
  computed: {
    ...mapGetters({
      DEP_ZONES: "Zone/DEP_ZONES",
    }),
  },
  watch: {
    modal(val) {
      if (val) {
        if (!this.DEP_ZONES.length) {
          this.getAllDepZone();
        }
      }
    },
  },
  methods: {
    ...mapActions({
      getAllDepZone: "Zone/GET_ALL_DEP_ZONE",
    }),
    async bindZone() {
      if (this.$root.isProvider) {
        showMessage("Вы пока не можете изменять данные филиала");
        return;
      }
      if (!this.searchZone) {
        showMessage("Необходимо выбрать зону доставки");
        return;
      }
      const payload = {
        departmentId: this.$route.params.id,
        zoneId: this.searchZone,
        isOn: true,
      };
      const response = await api.Zone.bindDepToZone(payload);
      if (response.type === "error") {
        showMessage(response.data.message);
      } else {
        this.$emit("update:modal", false);
        this.$emit("update-zones");
      }
    },
  },
};
</script>
