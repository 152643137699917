<template>
  <v-dialog
    v-model="showModal"
    activator="parent"
    :width="$vuetify.breakpoint.mdAndDown ? '95%' : '70%'"
    @click:outside="$emit('update:showModal', false)"
  >
    <v-card v-if="limit" class="modal" :dark="$dark.get()">
      <v-card-title class="flex justify-center">
        {{ editMode ? "Изменение" : "Создание" }} лимита, платной доставки
      </v-card-title>
      <v-card-text>
        <v-form ref="limit">
          <v-select
            v-if="departments.length"
            v-model="limit.departmentId"
            class="modal__select"
            label="Филиал"
            :disabled="editMode"
            :items="departments"
            item-text="departmentName"
            item-value="departmentId"
          ></v-select>
          <div class="modal_item">
            <p>Платная доставка</p>
            <v-switch
              v-model="limit.isPaidDelivery"
              color="#5d4bd0"
              inset
              label="вкл"
              hide-details
              class="switch mr-5"
            ></v-switch>
          </div>
          <div class="modal_item">
            <p>Мин. сумма заказа</p>
            <v-text-field
              v-model.number="limit.minOrderSum"
              label="сумма"
              dense
              solo
              flat
              class="modal_input"
              type="number"
              required
              :rules="!limit.isPaidDelivery ? [$validate.required] : []"
            />
          </div>
          <div class="modal_item">
            <p>Мин. сумма для доставки</p>
            <v-text-field
              v-model.number="limit.minOrderSumDelivery"
              label="сумма"
              dense
              solo
              flat
              class="modal_input"
              type="number"
              required
              :rules="limit.isPaidDelivery ? [$validate.required] : []"
            />
          </div>
          <div class="modal_item">
            <p>Радиус бесплатной доставки (м)</p>
            <v-text-field
              v-model.number="limit.freeRadius"
              label="метры"
              dense
              solo
              flat
              class="modal_input"
              type="number"
              required
              :rules="limit.isPaidDelivery ? [$validate.required] : []"
            />
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions class="flex justify-center">
        <v-btn rounded @click="$emit('update:showModal', false)">Отмена </v-btn>
        <v-btn v-if="editMode" rounded dark color="#5d4bd0" @click="checkForm()"
          >изменить
        </v-btn>
        <v-btn v-else rounded dark color="#5d4bd0" @click="checkForm()"
          >создать
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "LimitModal",
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    limit: {
      type: Object,
      default() {
        return {};
      },
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    departments: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      oldData: null,
      sendApi: false,
    };
  },
  watch: {
    showModal(val) {
      if (val) {
        this.oldData = { ...this.limit };
        this.sendApi = false;
      } else {
        if (!this.sendApi) {
          this.limit.isPaidDelivery = this.oldData.isPaidDelivery;
          this.limit.freeRadius = this.oldData.freeRadius;
          this.limit.minOrderSum = this.oldData.minOrderSum;
          this.limit.minOrderSumDelivery = this.oldData.minOrderSumDelivery;
        }
      }
    },
  },
  methods: {
    checkForm() {
      if (!this.$refs.limit.validate()) {
        return;
      }
      this.sendApi = true;
      this.editMode ? this.$emit("editLimit") : this.$emit("createLimit");
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  padding: 20px 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &__actions {
    width: 100%;
    justify-content: right;
  }
  &_input {
    max-width: 100px;
  }
  &_item {
    display: flex;
    gap: 10px;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    p {
      width: 60%;
      color: black;
      font-weight: 800;
    }
  }
}
</style>
