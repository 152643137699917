<template>
  <div class="diagram">
    <div class="diagram_top">
      <div class="diagram_line" :style="{ width: width1 }"></div>
      <div class="diagram_line2" :style="{ width: width2 }"></div>
    </div>
    <div class="diagram_bot">
      <div class="diagram_info">
        <div class="diagram_rect1"></div>
        <p class="diagram_text">Доставленных вовремя: {{ width1 }}</p>
      </div>
      <div class="diagram_info">
        <div class="diagram_rect2"></div>
        <p class="diagram_text">Доставленных с опозданием: {{ width2 }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Diagrams",
  props: {
    values: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      width1: 0,
      width2: 0,
    };
  },
  mounted() {
    this.setData();
  },
  methods: {
    setData() {
      const notInTime =
        ((this.values[0] - this.values[1]) / this.values[0]) * 100 || 0;
      const inTime = (this.values[1] / this.values[0]) * 100 || 0;
      this.width1 = `${inTime}%`;
      this.width2 = `${notInTime}%`;
    },
  },
};
</script>

<style lang="scss" scoped>
.diagram {
  width: 100%;

  &_top {
    display: flex;
    margin-bottom: 10px;
    border-radius: 5px;
    overflow: hidden;
  }
  &_bot {
    display: flex;
    gap: 10px;
  }
  &_info {
    display: flex;
    gap: 5px;
  }
  &_rect1 {
    width: 20px;
    height: 20px;
    background-color: #3a67d1;
    border-radius: 3px;
  }
  &_rect2 {
    width: 20px;
    height: 20px;
    background-color: #ff7200;
    border-radius: 3px;
  }
  &_line {
    height: 30px;
    background-color: #3a67d1;
  }
  &_line2 {
    height: 30px;
    background-color: #ff7200;
  }
}
</style>
